import React, { useEffect, useState } from 'react'
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

export default function Cricket() {

    const [data, setdata] = useState([])

    useEffect(() => {

        fetch('https://api.cricapi.com/v1/cricScore?apikey=2ec9c849-07cd-4640-a06d-cf952590f137').then((response) => response.json()).then((cricdata) => {
            console.log(cricdata)

            var filterdata = cricdata.data.filter((data) => data.series === 'ICC Mens T20 World Cup 2024');
            console.log(filterdata)

            setdata(filterdata);
        })

    },[])

    const getISTTime = (d) => {
            
        var date = new Date(d)
        console.log(d);

        let hours = date.getHours() + 5;
        let minutes = date.getMinutes() + 30;

        // Check whether AM or PM
        let newformat = hours >= 12 ? 'PM' : 'AM';

        if (minutes === 60) {
            hours = hours + 1;
            minutes = 0;
        }
       
        // Find current hour in AM-PM Format
        hours = hours % 12;

        // To display "0" as "12"
        hours = hours ? hours : 12;

        minutes = minutes < 10 ? '0' + minutes : minutes;

        console.log(hours + ':' + minutes + ' ' + newformat);

       

        return (hours + ':' + minutes + ' ' + newformat)
            
    }


    return (

        <>
        
            <div class="breadcrumb-wrap">
                <div class="container">
                    <h2 class="breadcrumb-title">Cricket Article</h2>
                    <ul class="breadcrumb-menu list-style">
                        <li><NavLink tag={Link} to="/">Home</NavLink></li>
                        <li>Business Article</li>
                    </ul>
                </div>
            </div>
            <div class="sports-wrap ptb-100">
                <div class="container">
                    <div class="row gx-55 gx-5">
                        <div class="col-lg-8">
                            <div class="row justify-content-center">

                                {

                                    data.map((item) =>

                                        <div class="col-md-6">
                                            <div class="news-card-thirteen">
                                                <div class="news-card-img">
                                                    <img src={item.t1img} alt="Iamge" />
                                                    Vs
                                                    <img src={item.t2img} alt="Iamge" />
                                                </div>
                                                <div class="news-card-info">
                                                    <h2>{item.status }</h2>
                                                    <h3><NavLink tag={Link} to={item.id}> {item.t1} vs {item.t2} </NavLink></h3>
                                                    <ul class="news-metainfo list-style">
                                                        <li><i class="fi fi-rr-calendar-minus"></i>{new Date(item.dateTimeGMT).toDateString()}</li>


                                                        <li><i class="fi fi-rr-clock-three"></i>{getISTTime(item.dateTimeGMT)}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                    )

                                }

                            </div>

                        </div>
                        <div class="col-lg-4">
                            <div class="sidebar">
                                <div class="sidebar-widget-two">
                                    <form action="#" class="search-box-widget">
                                        <input type="search" placeholder="Search" />
                                        <button type="submit">
                                            <i class="fi fi-rr-search"></i>
                                        </button>
                                    </form>
                                </div>
                                <div class="sidebar-widget">
                                    <h3 class="sidebar-widget-title">Categories</h3>
                                    <ul class="category-widget list-style">
                                        <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Celebration <span>(6)</span></a></li>
                                        <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Culture<span>(3)</span></a></li>
                                        <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Fashion<span>(2)</span></a></li>
                                        <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Inspiration<span>(8)</span></a></li>
                                        <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Lifestyle<span>(6)</span></a></li>
                                        <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Politics<span>(2)</span></a></li>
                                        <li><a href="#"><img src="assets/img/icons/arrow-right.svg" alt="Image" />Trending<span>(4)</span></a></li>
                                    </ul>
                                </div>
                                <div class="sidebar-widget">
                                    <h3 class="sidebar-widget-title">Recent Posts</h3>
                                    <div class="pp-post-wrap">
                                        <div class="news-card-one">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-thumb-4.webp" alt="Image" />
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">Bernie Nonummy Pelopai Iatis Eum Litora</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 22, 2023</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="news-card-one">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-thumb-5.webp" alt="Image" />
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">How Youth Viral Diseases May The Year 2023</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 23, 2023</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="news-card-one">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-thumb-6.webp" alt="Image" />
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">Man Wearing Black Pullover Hoodie To Smoke</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 14, 2023</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="news-card-one">
                                            <div class="news-card-img">
                                                <img src="assets/img/news/news-thumb-7.webp" alt="Image" />
                                            </div>
                                            <div class="news-card-info">
                                                <h3><a href="#">First Prototype Flight Using Kinetic Launch System</a></h3>
                                                <ul class="news-metainfo list-style">
                                                    <li><i class="fi fi-rr-calendar-minus"></i><a href="#">Apr 07, 2023</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sidebar-widget">
                                    <h3 class="sidebar-widget-title">Popular Tags</h3>
                                    <ul class="tag-list list-style">
                                        <li><a href="#">BUSINESS</a></li>
                                        <li><a href="#">FOOD</a></li>
                                        <li><a href="#">SCIENCE</a></li>
                                        <li><a href="#">LIFESTYLE</a></li>
                                        <li><a href="#">SPORTS</a></li>
                                        <li><a href="#">PHOTO</a></li>
                                        <li><a href="#">TECHNOLOGY</a></li>
                                        <li><a href="#">CONTENT</a></li>
                                        <li><a href="#">FEATURED</a></li>
                                        <li><a href="#">AUDIO</a></li>
                                        <li><a href="#">FASHION</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )

}