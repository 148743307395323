import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import Category from './components/Category';
import DetailsPage from './components/DetailsPage';
import cricket from './components/Cricket';

/*import './custom.css'*/

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
        <Layout>
            <Switch>
                <Route exact path='/' component={Home} />
                <Route exact path='/cricket' component ={ cricket }/>
                <Route exact path='/trending' component={Category} />
                <Route exact path='/trending/:ids' component={DetailsPage} />

                <Route exact path='/humor' component={Category} />
                <Route exact path='/humor/:ids' component={DetailsPage} />

                <Route exact path='/health' component={Category} />
                <Route exact path='/health/:ids' component={DetailsPage} />

                <Route exact path='/technology' component={Category} />
                <Route exact path='/technology/:ids' component={DetailsPage} />

                <Route exact path='/ecoindia' component={Category} />
                <Route exact path='/ecoindia/:ids' component={DetailsPage} />

                <Route exact path='/video' component={Category} />
                <Route exact path='/video/:ids' component={DetailsPage} />

                <Route exact path='/business/finance' component={Category} />
                <Route exact path='/business/finance/:ids' component={DetailsPage} />
                <Route exact path='/business/' component={Category} />
                <Route exact path='/business/:ids' component={DetailsPage} />

                <Route exact path='/lifestyle/travel/' component={Category} />
                <Route exact path='/lifestyle/travel/:ids' component={DetailsPage} />

                <Route exact path='/lifestyle/' component={Category} />
                <Route exact path='/lifestyle/:ids' component={DetailsPage} />
               

                <Route exact path='/sports' component={Category} />


                
                <Route exact path='/reviews/web-series/' component={Category} />
                <Route exact path='/reviews/web-series/:ids' component={DetailsPage} />

                <Route exact path='/reviews/movies/' component={Category} />
                <Route exact path='/reviews/movies/:ids' component={DetailsPage} />

                <Route exact path='/reviews/ott-platform/' component={Category} />
                <Route exact path='/reviews/ott-platform/:ids' component={DetailsPage} />

                <Route exact path='/reviews/' component={Category} />
                <Route exact path='/reviews/:ids' component={DetailsPage} />

                <Route exact path='/fetch-data' component={FetchData} />
               
                <Route exact path='/sports/cricket/' component={Category} />
                <Route exact path='/sports/cricket/:ids' component={DetailsPage} />

                <Route exact path='/sports/tennis/' component={Category} />
                <Route exact path='/sports/tennis/:ids' component={DetailsPage} />

                <Route exact path='/sports/hockey/' component={Category} />
                <Route exact path='/sports/hockey/:ids' component={DetailsPage} />

                <Route exact path='/sports/football' component={Category} />
                <Route exact path='/sports/football/:ids' component={DetailsPage} />

                <Route exact path='/sports/kabaddi' component={Category} />
                <Route exact path='/sports/kabaddi/:ids' component={DetailsPage} />

                <Route exact path='/sports/' component={Category} />
                <Route exact path='/sports/:ids' component={DetailsPage} />

                <Route exact path='/:id/' component={DetailsPage} />

            </Switch>

        </Layout>
    );
  }
}
